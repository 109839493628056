<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col cols="12" lg="6" xl="4" md="8" sm="10" class="mb-25">
        <div class="login-form-box">
          <h4>
            <span><font-awesome-icon icon="lock" /></span
            >{{ $t("password_forgot") }}
          </h4>
          <div>
            <div
              v-if="emailState === false && emailError !== ''"
              class="forget-password-error"
            >
              {{ $t("not_recognize_email") }}
            </div>
            <div class="pb-25">
              <p>
                {{ $t("forgot_title") }}
              </p>
            </div>
            <b-form-group label-for="email" :description="description">
              <div class="form-group">
                <b-form-input
                  id="email"
                  v-model="email"
                  type="text"
                  aria-describedby="email-feedback"
                  placeholder="e-mailadres"
                  class="form-item"
                  :state="emailState"
                ></b-form-input>

                <b-form-invalid-feedback id="email-feedback">
                  {{ emailError }}
                </b-form-invalid-feedback>
              </div>
              <div class="text-center">
                <b-button @click="formSubmit" class="green-btn">{{
                  $t("send_email")
                }}</b-button>
              </div>
              <div class="cancel-text">
                <b-link to="/login/">{{ $t("cancel") }}</b-link>
              </div>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { forgotPassword } from "@storefront/core/data-resolver/user";
export default {
  name: "ForgotPassword",
  mounted() {
    const bcrumb = { current: this.$t("password_forgot"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("password_forgot") },
        { name: "keywords", content: this.$t("password_forgot") },
        { name: "description", content: this.$t("password_forgot") },
      ],
      title: this.$t("password_forgot"),
    };
  },
  data() {
    return {
      email: "",
      emailState: null,
      emailError: "",
      description: "",
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    async formSubmit() {
      if (this.validateEmail()) {
        const retval = await forgotPassword(this.email);
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("account"),
            text: this.$t("reset_password_email_has_been_sent"),
          };
          this.$store.dispatch("messages/sendMessage", {
            message: msg,
          });
        }
      }
    },
    validateEmail() {
      if (this.email == "") {
        this.emailState = false;
        this.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.email)) {
        this.emailState = false;
        this.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      this.emailState = true;
      return true;
    },
  },
};
</script>
