<template>
  <div>
    <b-container>
      <div class="header-top-right-content-mobile">
        <div class="content">
          <cmsBlockSimple
            v-if="typeof headerTopRightBlock == 'object'"
            :identifier="headerTopRightBlock.identifier"
          />
        </div>
      </div>
    </b-container>
    <div class="forget-password-page">
      <ForgotPasswordComponent />
    </div>
  </div>
</template>
<script>
import ForgotPasswordComponent from "@/esf_utrecht_bankxl/core/components/auth/ForgotPasswordComponent.vue";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";
export default {
  name: "ForgotPassword",
  components: {
    ForgotPasswordComponent,
    cmsBlockSimple,
  },
  computed: {
    headerTopRightBlock() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header_top_right_block"
      );
    },
  },
  metaInfo() {
    return {
      title: this.$t("forget_password"),
    };
  },
};
</script>
